<template>
    <div class="language-management">
      <h3>Dil Yönetimi</h3>

      <p style="color: yellow;"> DIKKAT: EKELENEN DİLİ SİLMEYİN. ÇOK ÖNEMLİYSE PASIF YAPIN</p>
  
      <h4>{{ isEdit ? "Dili Düzenle" : "Yeni Dil Ekle" }}</h4>
      <form @submit.prevent="isEdit ? updateLanguage() : createLanguage()">
        <input v-model="selectedLanguage.name" placeholder="Dil Adı" required />
        <input v-model="selectedLanguage.symbol" placeholder="Dil Sembolü" required />
        <textarea v-model="selectedLanguage.icon" placeholder="SVG Kodunu Girin" required></textarea>
        <label>
          <input type="checkbox" v-model="selectedLanguage.is_active" /> Aktif
        </label>
        <button type="submit">{{ isEdit ? "Güncelle" : "Dil Ekle" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </form>
  
      <div class="table-header">
        <h4>Tüm Diller</h4>
        <label>
          <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
        </label>
      </div>

      <p>LÜTFEN DİKKAT: tabloda gördüğünüz ikonlar SVG içeriğe sahip olduğu için css stilleri doğru görüntülenmez.
        <br>
        Aynı sınıfa ait renkler farklı gözükebilir. bu nedenle SVG ikonlarınıza ebnzersiz sınıflar tanımlayın. Teşkürler.
      </p>
  
      <table class="language-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Dil Adı</th>
            <th>Sembol</th>
            <th>Icon</th>
            <th>Aktif</th>
            <th>Düzenle</th>
            <th v-if="showDeleteButtons">Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="language in languages" :key="language.language_id">
            <td>{{ language.language_id }}</td>
            <td>{{ language.name }}</td>
            <td>{{ language.symbol }}</td>
            <td v-html="language.icon"></td>
            <td>{{ language.is_active ? "Evet" : "Hayır" }}</td>
            <td><button @click="editLanguage(language)">Düzenle</button></td>
            <td v-if="showDeleteButtons"><button class="deletebutton" @click="confirmDelete(language.language_id)">Sil</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        languages: [],
        selectedLanguage: {
          language_id: null,
          name: '',
          symbol: '',
          icon: '',
          is_active: true,
        },
        isEdit: false,
        showDeleteButtons: false,
      };
    },
    methods: {
      async fetchLanguages() {
        try {
          const response = await axios.get('/admin/languages');
          this.languages = response.data.languages;
        } catch (error) {
          console.error('Diller alınırken hata oluştu:', error);
        }
      },
      async createLanguage() {
        try {
          await axios.post('/admin/languages', {
            name: this.selectedLanguage.name,
            symbol: this.selectedLanguage.symbol,
            icon: this.selectedLanguage.icon,
            is_active: this.selectedLanguage.is_active,
          });
          this.fetchLanguages();
          this.resetForm();
        } catch (error) {
          console.error('Dil eklenirken hata oluştu:', error);
        }
      },
      editLanguage(language) {
        this.selectedLanguage = { ...language };
        this.isEdit = true;
      },
      async updateLanguage() {
        try {
          await axios.put(`/admin/languages/${this.selectedLanguage.language_id}`, {
            name: this.selectedLanguage.name,
            symbol: this.selectedLanguage.symbol,
            icon: this.selectedLanguage.icon,
            is_active: this.selectedLanguage.is_active,
          });
          this.fetchLanguages();
          this.resetForm();
        } catch (error) {
          console.error('Dil güncellenirken hata oluştu:', error);
        }
      },
      async deleteLanguage(id) {
        try {
          await axios.delete(`/admin/languages/${id}`);
          this.fetchLanguages();
        } catch (error) {
          console.error('Dil silinirken hata oluştu:', error);
        }
      },
      confirmDelete(id) {
        if (confirm('Bu dili silmek istediğinizden emin misiniz?')) {
          this.deleteLanguage(id);
        }
      },
      resetForm() {
        this.selectedLanguage = {
          language_id: null,
          name: '',
          symbol: '',
          icon: '',
          is_active: true,
        };
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchLanguages();
    },
  };
  </script> 