<template>
    <div>
      <h2>Kullanıcı Tarafından Silinen Notlar</h2>
      <table>
        <thead>
          <tr>
            <th>User ID</th>
            <th>Note Title</th>
            <th>Note Text</th>
            <th>Reminder</th>
            <th>Is Archived</th>
            <th>Is Deleted</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="note in notes" :key="note.id">
            <td>{{ note.user_id }}</td>
            <td>{{ note.note_title }}</td>
            <td>{{ note.note_text }}</td>
            <td>{{ formatDateTime(note.reminder) }}</td>
            <td>{{ note.is_archived ? 'Yes' : 'No' }}</td>
            <td>{{ note.is_deleted ? 'Yes' : 'No' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    name: 'DeletedNotesTable',
    data() {
      return {
        notes: []
      };
    },
    methods: {
      fetchDeletedNotes() {
        axios.get('/admin/mynotes/notes/deleted')
          .then(response => {
            this.notes = response.data;
          })
          .catch(error => {
            console.error('Silinmiş notlar getirilirken hata:', error);
          });
      },
      formatDateTime(datetime) {
        return datetime ? new Date(datetime).toLocaleString() : '';
      }
    },
    mounted() {
      this.fetchDeletedNotes();
    }
  };
  </script>
  
  <style scoped> 
  </style>
  