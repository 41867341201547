<template>
  <div>
    <div class="button-container">
      <button
        v-for="(item, index) in components"
        :key="index"
        :class="{ active: currentComponent === item.component }"
        @click="currentComponent = item.component"
      >
        {{ item.name }}
      </button>
    </div>
    <div class="component-wrap"><component :is="currentComponent"></component></div>
  </div>
</template>

<script>
import CategoryManager from './CategoryManager.vue';
import ProductGroup from './SubcategoryManager.vue';
import ProductManager from './ProductManager.vue';
import StaticPages from './StaticPages.vue';
import AgreementManagement from './AgreementManagement.vue';
import MessageMain from './MessageMain.vue';
import EducationMain from './education/EducationMain.vue';
import MinorCategoryManager from './MinorCategoryManager.vue';
import DocumentManager from './DocumentManager.vue';
import GeneralDocumentManager from './GeneralDocumentManager.vue';
import MasterWorldMain from './MasterWorldMain.vue';
import FaqManager from './FaqManager.vue';
import AnnouncementManager from './AnnouncementManager.vue';
import ApplicationManager from './ApplicationManager.vue';
import ShowroomManager from './ShowroomManager.vue';
import QuoteManager from './QuoteManager.vue';
import ChatMain from './ChatMain.vue';
import ForumMain from './sorusor/ForumMain.vue';
import RandevuMain from './randevu/RandevuMain.vue';
import AccountingMain from './muhasebe/AccountingMain.vue';
import NotesMain from './notes/NotesMain.vue';
import HowToMain from './kullanim/HowToMain.vue';
import GptMain from './gpt/GptMain.vue';
import LanguageManager from './language/LanguageManager.vue';

export default {
  data() {
    return {
      currentComponent: 'CategoryManager', 
      components: [  
        { name: 'Ana Sayfa Kategoriler', component: 'CategoryManager' },
        { name: 'Ürün Kategorileri', component: 'ProductGroup' },
        { name: 'Gösterilen Ürünler', component: 'MinorCategoryManager' },
        { name: 'Ürün yönetimi / PROMO', component: 'ProductManager' },
        { name: 'Ürün Belgeleri', component: 'DocumentManager' },
        { name: 'Uluslararası Belgeler', component: 'GeneralDocumentManager' },
        { name: 'Uygulamalar', component: 'ApplicationManager' },
        { name: 'Mesaj Sistemi', component: 'MessageMain' },
        { name: 'Eğitim Yönetimi', component: 'EducationMain' },
        { name: 'Master World', component: 'MasterWorldMain' }, 
        { name: 'Sabit sayfalar', component: 'StaticPages' },
        { name: 'Showroom', component: 'ShowroomManager' },
        { name: 'Özet bilgiler', component: 'QuoteManager' },
        { name: 'Chat Yönetim', component: 'ChatMain' },
        { name: 'SSS', component: 'FaqManager' }, 
        { name: 'Sözleşmeler', component: 'AgreementManagement' },
        { name: 'Duyurular', component: 'AnnouncementManager' },
        { name: 'Forum', component: 'ForumMain' },
        { name: 'NoahAI', component: 'GptMain' },
        { name: 'Kullanıcı Randevu Sistemi', component: 'RandevuMain' },
        { name: 'Kullanıcı Muhasebe Sistemi', component: 'AccountingMain' },
        { name: 'Notlar', component: 'NotesMain' },
        { name: 'Kullanım Klavuzu', component: 'HowToMain' },
        { name: 'Dil Yönetimi', component: 'LanguageManager' },
      ],
    };
  },
  components: {
    CategoryManager,
    ProductGroup,
    ProductManager,
    StaticPages,
    AgreementManagement,
    MessageMain,
    EducationMain,
    MinorCategoryManager,
    DocumentManager,
    GeneralDocumentManager,
    MasterWorldMain,
    FaqManager,
    AnnouncementManager,
    ApplicationManager,
    ShowroomManager,
    QuoteManager,
    ChatMain,
    ForumMain,
    RandevuMain,
    AccountingMain,
    NotesMain,
    HowToMain,
    GptMain,
    LanguageManager 
  },
};
</script>
  
<style scoped>
.button-container {
margin: 1em 0rem; 
text-align: center;
display: flex; 
flex-wrap: wrap; 
background-color: #000000;
padding: 2em;
} 

.button-container button { 
border-radius: 0rem;
display: inline-block;
padding: 0.4rem 2rem;
min-width: 6rem;
margin: 0.2rem;
border: 0.06rem solid #262626;
border: none;
background-color: #0b0e0f;
color: #b5b5b5;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
} 
.button-container button:hover {
background-color: #000000;  
color: #d9dee0;
} 
.button-container button.active {
background-color: #3d0558;
color: #b5b5b5; 
} 
@media (max-width: 360px) {
.button-container {
  flex-direction: column;
} 
.button-container button {
  width: 90%;
  margin: 0.2em auto; 
}
}
</style>
  