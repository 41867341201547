<template>
    <div>
      <h2>Yeni Not Ekle</h2>
      <form @submit.prevent="addNote">
        <div>
          <label>User ID:</label>
          <input type="number" v-model="form.user_id" required />
        </div>
        <div>
          <label>Note Title:</label>
          <input type="text" v-model="form.note_title" required />
        </div>
        <div>
          <label>Note Text:</label>
          <textarea v-model="form.note_text" required maxlength="5000"></textarea>
        </div>
        <div>
          <label>Reminder:</label>
          <input type="datetime-local" v-model="form.reminder" />
        </div>
        <div>
          <label>Is Archived:</label>
          <select v-model="form.is_archived">
            <option :value="false">No</option>
            <option :value="true">Yes</option>
          </select>
        </div>
        <button type="submit">Not Ekle</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    name: 'AddNote',
    data() {
      return {
        form: {
          user_id: '',
          note_title: '',
          note_text: '',
          reminder: '',
          is_archived: false
        }
      };
    },
    methods: {
      addNote() {
        axios.post('/admin/mynotes/notes', this.form)
          .then(() => {
            alert('Not eklendi.');
            // Formu temizle
            this.form = {
              user_id: '',
              note_title: '',
              note_text: '',
              reminder: '',
              is_archived: false
            };
          })
          .catch(error => {
            console.error('Not eklenirken hata:', error);
          });
      }
    }
  };
  </script>
  
  <style scoped>
  form div {
    margin-bottom: 10px;
  }
  </style>
  