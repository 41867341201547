<template>
    <div class="report-management">
      <h3>Rapor Yönetimi</h3>
      <table>
        <thead>
          <tr>
            <th>Tip</th>
            <th>Raporlayan Kullanıcı</th>
            <th>Neden</th>
            <th>Tarih</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="report in reports" :key="report.report_id">
            <td>{{ report.entity_type }}</td>
            <td>{{ report.user_id }}</td>
            <td>{{ report.reason }}</td>
            <td>{{ report.created_at }}</td>
            <td>
              <button @click="handleReport(report)">İşlem Yap</button>
            </td>
          </tr>
          <tr v-if="reports.length === 0">
            <td colspan="5">Rapor bulunamadı.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "ReportManagement",
    data() {
      return {
        reports: [],
      };
    },
    methods: {
      async fetchReports() {
        try {
          const response = await axios.get("/admin/forumreports");
          this.reports = response.data.data || [];
        } catch (error) {
          console.error("Raporlar alınırken hata oluştu:", error);
        }
      },
      async handleReport(report) {
        try {
          const endpoint =
            report.entity_type === "Question"
              ? `/admin/forumquestions/${report.entity_id}/reports`
              : `/admin/forumanswers/${report.entity_id}/reports`;
          await axios.put(endpoint);
          alert("Rapor işleme alındı.");
          this.fetchReports();
        } catch (error) {
          console.error("Rapor işleme alınamadı:", error);
        }
      },
    },
    mounted() {
      this.fetchReports();
    },
  };
  </script>
  
  <style scoped>
  .report-management {
    padding: 20px;
  }
  </style>
  