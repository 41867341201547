<template>
  <div> Başlamadan önce: Lütfen Google API bağlantınızı yapın > Sys Sekmesinde bulabilirsiniz.
  </div>
</template>

<script>
export default { 
}
</script>

<style scoped> 
</style>
