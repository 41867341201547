<template>
    <div>
      <h2>Kılavuz Görselleri Yönet</h2>
  
      <!-- Yeni veya Güncellenen Görsel Ekleme Formu -->
      <h3>{{ isEditing ? "Görseli Güncelle" : "Yeni Görsel Ekle" }}</h3>
      <form @submit.prevent="isEditing ? updateImage() : addImage()">
        <label>Kategori:</label>
        <select v-model="newImage.category_id" required>
          <option disabled value="">Kategori Seçin</option>
          <option v-for="category in categories" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </select>
  
        <label>Başlık:</label>
        <input type="text" v-model="newImage.title" required />
  
        <label>Detay:</label>
        <textarea v-model="newImage.detail"></textarea>
  
        <label>Görsel Seç:</label>
        <input type="file" @change="handleFileUpload" />
        <img v-if="previewImage" :src="previewImage" alt="Önizleme" width="200" />
  
        <button type="submit">{{ isEditing ? "Güncelle" : "Ekle" }}</button>
        <button v-if="isEditing" type="button" @click="cancelEdit">İptal</button>
      </form>
  
      <!-- Görseller Tablosu -->
      <h3>Mevcut Görseller</h3>
      <table>
        <thead>
          <tr>
            <th>Kategori</th>
            <th>Başlık</th>
            <th>Detay</th>
            <th>Görsel</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="image in images" :key="image.id">
            <td>{{ image.category_name }}</td>
            <td>{{ image.title }}</td>
            <td>{{ image.detail }}</td>
            <td>
              <img :src="image.imagelink" alt="Görsel" width="200" v-if="image.imagelink" />
            </td>
            <td>
              <button @click="editImage(image)">Düzenle</button>
              <button class="delete" @click="deleteImage(image.id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        categories: [],
        images: [],
        newImage: {
          id: null,
          category_id: '',
          title: '',
          detail: '',
          file: null,
        },
        isEditing: false,
        previewImage: null,
      };
    },
    methods: {
      async fetchCategories() {
        try {
          const response = await axios.get('/admin/howtousecategories');
          this.categories = response.data;
        } catch (error) {
          console.error('Kategoriler alınırken hata:', error);
        }
      },
      async fetchImages() {
        try {
          const response = await axios.get('/admin/howtouseimages');
          this.images = response.data;
        } catch (error) {
          console.error('Görseller alınırken hata:', error);
        }
      },
      handleFileUpload(event) {
        const file = event.target.files[0];
        this.newImage.file = file;
  
        // Önizleme için geçici URL oluştur
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.previewImage = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      },
      async addImage() {
        try {
          const formData = new FormData();
          formData.append('category_id', this.newImage.category_id);
          formData.append('title', this.newImage.title);
          formData.append('detail', this.newImage.detail);
          formData.append('image', this.newImage.file);
  
          await axios.post('/admin/howtouseimages', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
  
          this.fetchImages();
          this.resetForm();
        } catch (error) {
          console.error('Görsel eklenirken hata:', error);
        }
      },
      async updateImage() {
        try {
          const formData = new FormData();
          formData.append('category_id', this.newImage.category_id);
          formData.append('title', this.newImage.title);
          formData.append('detail', this.newImage.detail);
          if (this.newImage.file) {
            formData.append('image', this.newImage.file);
          }
  
          await axios.put(`/admin/howtouseimages/${this.newImage.id}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
  
          this.fetchImages();
          this.resetForm();
        } catch (error) {
          console.error('Görsel güncellenirken hata:', error);
        }
      },
      async deleteImage(id) {
        if (!confirm('Bu görseli silmek istediğinizden emin misiniz?')) return;
        try {
          await axios.delete(`/admin/howtouseimages/${id}`);
          this.fetchImages();
        } catch (error) {
          console.error('Görsel silinirken hata:', error);
        }
      },
      editImage(image) {
        this.newImage = {
          id: image.id,
          category_id: image.category_id,
          title: image.title,
          detail: image.detail,
          file: null,
        };
        this.previewImage = image.imagelink;
        this.isEditing = true;
      },
      cancelEdit() {
        this.resetForm();
      },
      resetForm() {
        this.newImage = {
          id: null,
          category_id: '',
          title: '',
          detail: '',
          file: null,
        };
        this.previewImage = null;
        this.isEditing = false;
      },
    },
    mounted() {
      this.fetchCategories();
      this.fetchImages();
    },
  };
  </script>
  
  <style scoped> 
  </style>
  