<template>
    <div>
      <div class="sub-button-container">
        <button
          v-for="(item, index) in components"
          :key="index"
          :class="{ active: currentComponent === item.component }"
          @click="currentComponent = item.component"
        >
          {{ item.name }}
        </button>
      </div>
      <component :is="currentComponent"></component>
    </div>
  </template>
  
  <script>    
import AddNote from './AddNote.vue'; 
import AdminNotesTable from './AdminNotesTable.vue';
import DeletedNotesTable from './DeletedNotesTable.vue';
import UserDeletedNotes from './UserDeletedNotes.vue';
import UserNotesSearch from './UserNotesSearch.vue';
  
  export default {
    data() {
      return {
        currentComponent: 'AddNote', 
        components: [ 
          { name: 'Not Ekle', component: 'AddNote' },
          { name: 'Tüm Kullanıcı Notları', component: 'AdminNotesTable' }, 
          { name: 'Kullanıcı Notları', component: 'UserNotesSearch' },
          { name: 'Kullanıcı silinen Notları', component: 'UserDeletedNotes' }, 
          { name: 'Silinen Notlar', component: 'DeletedNotesTable' },    
        ],
      };
    },
    components: {    
      AddNote,
      AdminNotesTable,
      DeletedNotesTable,
      UserNotesSearch,
      UserDeletedNotes

    },
  };
  </script>
    
  <style scoped> 
  </style>
    