<template>
    <div class="question-tag-manager">
      <h3>Soru ve Etiket Yönetimi</h3>
  
      <div class="filters">
        <input v-model="searchQuery" type="text" placeholder="Sorularda ara..." />
        <button @click="fetchQuestions">Ara</button>
      </div>
  
      <table v-if="questions.length">
        <thead>
          <tr>
            <th>Başlık</th>
            <th v-for="tag in tags" :key="getTagId(tag)">{{ tag.tag_name }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="question in filteredQuestions" :key="question.question_id">
            <td>{{ question.title }}</td>
            <td v-for="tag in tags" :key="getTagId(tag)">
              <input
                type="checkbox"
                :checked="isTagLinked(question.question_id, getTagId(tag))"
                @change="toggleTag(question.question_id, getTagId(tag))"
              />
            </td>
          </tr>
        </tbody>
      </table>
  
      <p v-if="questions.length === 0">Soru bulunamadı.</p>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        questions: [],
        tags: [],
        linkedTags: {}, 
        searchQuery: ""
      };
    },
    computed: {
      filteredQuestions() {
        return this.questions.filter(question =>
          question.title.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
    },
    methods: {
      async fetchQuestions() {
        try { 
          const response = await axios.get("/admin/forumquestions");
          this.questions = response.data.data || []; 
          await this.fetchLinkedTags();  
        } catch (error) { 
          alert("❌ Sorular alınırken hata oluştu!");
        }
      },
      async fetchTags() {
        try { 
          const response = await axios.get("/admin/forumtags");
          this.tags = response.data.data || []; 
        } catch (error) { 
          alert("❌ Etiketler alınırken hata oluştu!");
        }
      },
      async fetchLinkedTags() {
        try { 
          const response = await axios.get("/admin/forumquestionswithtags");
          const data = response.data.data || [];
          
          const mapping = {};
          data.forEach(item => {
            const questionId = item.question_id;
            const tagId = item.tag_id;
  
            if (questionId && tagId) {
              if (!mapping[questionId]) {
                mapping[questionId] = [];
              }
              mapping[questionId].push(tagId);
            }
          });
  
          this.linkedTags = mapping; 
        } catch (error) { 
          alert("❌ Etiket eşleşmeleri alınırken hata oluştu!");
        }
      },
      isTagLinked(questionId, tagId) {
        return this.linkedTags[questionId]?.includes(tagId) ?? false;
      },
      async toggleTag(questionId, tagId) {
        if (!tagId) { 
          alert("❌ Geçersiz etiket ID!");
          return;
        } 
  
        try {
          if (this.isTagLinked(questionId, tagId)) { 
            await axios.delete(`/admin/forumquestions/${questionId}/tags/${tagId}`);
            this.linkedTags[questionId] = this.linkedTags[questionId].filter(id => id !== tagId); 
          } else { 
            await axios.post(`/admin/forumquestions/${questionId}/tags/${tagId}`);
            if (!this.linkedTags[questionId]) {
              this.linkedTags[questionId] = [];
            }
            this.linkedTags[questionId].push(tagId); 
          }
        } catch (error) { 
          alert("❌ Etiket bağlantısı değiştirirken hata oluştu!");
        }
      },
      getTagId(tag) {
        const id = tag.id || tag.tag_id; 
        if (!id) {
          alert("❌ Etiket ID tanımlı değil!");
        }
        return id;
      }
    },
    async mounted() { 
      await this.fetchQuestions();
      await this.fetchTags();
      await this.fetchLinkedTags();
    }
  };
  </script>
  
  <style scoped>
  .question-tag-manager {
    padding: 20px;
  }
  
  .filters {
    margin-bottom: 20px;
  }
  
  .filters input {
    margin-right: 10px;
  }
  </style>
  