<template>
    <div>
      <h2>Kullanıcı silinmiş Notlarını Ara ve Düzenle</h2>
      <input type="number" v-model="userId" placeholder="User ID girin" />
      <button @click="searchNotes">Ara</button>
      
      <div v-if="notes.length">
        <table>
          <thead>
            <tr>
              <th>Note Title</th>
              <th>Note Text</th>
              <th>Reminder</th>
              <th>Is Archived</th>
              <th>Is Deleted</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="note in notes" :key="note.id">
              <td>
                <input type="text" v-model="note.note_title" @blur="updateNote(note)" />
              </td>
              <td>
                <input type="text" v-model="note.note_text" @blur="updateNote(note)" />
              </td>
              <td>
                <input type="datetime-local" v-model="note.reminder" @blur="updateNote(note)" />
              </td>
              <td>
                <select v-model="note.is_archived" @change="updateNote(note)">
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </td>
              <td>
                <select v-model="note.is_deleted" @change="updateNote(note)">
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    name: 'UserDeletedNotesSearch',
    data() {
      return {
        userId: '',
        notes: []
      };
    },
    methods: {
      searchNotes() {
        if (!this.userId) {
          alert('Lütfen user_id girin.');
          return;
        }
        axios.get(`/admin/mynotes/deletednotes/user/${this.userId}`)
          .then(response => {
            this.notes = response.data;
          })
          .catch(error => {
            console.error('Kullanıcı notları getirilirken hata:', error);
          });
      },
      updateNote(note) {
        axios.put(`/admin/mynotes/notes/${note.id}`, note)
          .then(() => {
            // Güncelleme başarılı, istersen toast bildirimi ekleyebilirsin.
          })
          .catch(error => {
            console.error('Not güncellenirken hata:', error);
          });
      }
    }
  };
  </script>
  
  <style scoped> 
  </style>
  