<template>
    <div>
      <div class="sub-button-container">
        <button
          v-for="(item, index) in components"
          :key="index"
          :class="{ active: currentComponent === item.component }"
          @click="currentComponent = item.component"
        >
          {{ item.name }}
        </button>
      </div>
      <component :is="currentComponent"></component>
    </div>
  </template>
  
  <script>     
import GptManager from './GptManager.vue';
import GptNames from './GptNames.vue';
import KnowledgeDocuments from './KnowledgeDocuments.vue';
  
  export default {
    data() {
      return {
        currentComponent: 'GptManager', 
        components: [ 
          { name: 'Aktif GPTler', component: 'GptNames' }, 
          { name: 'AI Yönetim', component: 'GptManager' }, 
          { name: 'AI Dokümanları Yönetim', component: 'KnowledgeDocuments' }, 
        ],
      };
    },
    components: {     
      GptManager,
      GptNames,
      KnowledgeDocuments

    },
  };
  </script>
    
  <style scoped> 
  </style>
    