<template>
    <div>
      <h2>Tüm Notlar</h2>
      <table>
        <thead>
          <tr>
            <th>User ID</th>
            <th>Note Title</th>
            <th>Note Text</th>
            <th>Reminder</th>
            <th>Is Archived</th>
            <th>Is Deleted</th>
            <th>Hard Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="note in notes" :key="note.id">
            <td>{{ note.user_id }}</td>
            <!-- Note Title: Tıklanınca input görünür -->
            <td>
              <div v-if="editing.noteId === note.id && editing.field === 'note_title'">
                <input type="text" v-model="note.note_title" @blur="saveEdit(note)" />
              </div>
              <div v-else @click="setEditing(note.id, 'note_title')">
                {{ note.note_title }}
              </div>
            </td>
            <!-- Note Text -->
            <td>
              <div v-if="editing.noteId === note.id && editing.field === 'note_text'">
                <input type="text" v-model="note.note_text" @blur="saveEdit(note)" />
              </div>
              <div v-else @click="setEditing(note.id, 'note_text')">
                {{ note.note_text }}
              </div>
            </td>
            <!-- Reminder -->
            <td>
              <div v-if="editing.noteId === note.id && editing.field === 'reminder'">
                <input type="datetime-local" v-model="note.reminder" @blur="saveEdit(note)" />
              </div>
              <div v-else @click="setEditing(note.id, 'reminder')">
                {{ formatDateTime(note.reminder) }}
              </div>
            </td>
            <!-- is_archived -->
            <td>
              <div v-if="editing.noteId === note.id && editing.field === 'is_archived'">
                <select v-model="note.is_archived" @change="saveEdit(note)" @blur="clearEditing">
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </div>
              <div v-else @click="setEditing(note.id, 'is_archived')">
                {{ note.is_archived ? 'Yes' : 'No' }}
              </div>
            </td>
            <!-- is_deleted -->
            <td>
              <div v-if="editing.noteId === note.id && editing.field === 'is_deleted'">
                <select v-model="note.is_deleted" @change="saveEdit(note)" @blur="clearEditing">
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </div>
              <div v-else @click="setEditing(note.id, 'is_deleted')">
                {{ note.is_deleted ? 'Yes' : 'No' }}
              </div>
            </td>
            <!-- Hard Delete Button -->
            <td>
              <button @click="hardDelete(note.id)">Hard Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';

  export default {
    name: 'AdminNotesTable',
    data() {
      return {
        notes: [],
        editing: {
          noteId: null,
          field: null
        }
      };
    },
    methods: {
      fetchNotes() {
        axios.get('/admin/mynotes/notes')
          .then(response => {
            this.notes = response.data;
          })
          .catch(error => {
            console.error('Notları getirirken hata:', error);
          });
      },
      setEditing(noteId, field) {
        this.editing.noteId = noteId;
        this.editing.field = field;
      },
      clearEditing() {
        this.editing.noteId = null;
        this.editing.field = null;
      },
      saveEdit(note) {
        axios.put(`/admin/mynotes/notes/${note.id}`, note)
          .then(() => {
            this.fetchNotes();
            this.clearEditing();
          })
          .catch(error => {
            console.error('Not güncellenirken hata:', error);
          });
      },
      hardDelete(noteId) {
        if (confirm('Notu kalıcı olarak silmek istediğine emin misin?')) {
          axios.delete(`/admin/mynotes/notes/${noteId}`)
            .then(() => {
              this.fetchNotes();
            })
            .catch(error => {
              console.error('Not silinirken hata:', error);
            });
        }
      },
      formatDateTime(datetime) {
        return datetime ? new Date(datetime).toLocaleString() : '';
      }
    },
    mounted() {
      this.fetchNotes();
    }
  };
  </script>
  
  <style scoped> 
  </style>
  