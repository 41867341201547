<template>
    <div class="answer-list">
      <h3>Yanıtlar</h3>
      <div>
        <select v-model="statusFilter" @change="fetchAnswers">
          <option value="">Tümü</option>
          <option value="Pending">Beklemede</option>
          <option value="Approved">Onaylandı</option>
          <option value="Deleted">Silindi</option>
        </select>
      </div>
      <table>
        <thead>
          <tr>
            <th>Yanıt</th>
            <th>Durum</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="answer in answers" :key="answer.answer_id">
            <td>{{ answer.content }}</td>
            <td>{{ answer.status }}</td>
            <td>
              <button @click="viewDetails(answer.answer_id)">Detay</button>
            </td>
          </tr>
          <tr v-if="answers.length === 0">
            <td colspan="3">Yanıt bulunamadı.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "AnswerList",
    data() {
      return {
        answers: [],
        statusFilter: "",
      };
    },
    methods: {
      async fetchAnswers() {
        const questionId = this.$route.params.id;
        try {
          const response = await axios.get(`/admin/forumquestions/${questionId}/answers`, {
            params: { status: this.statusFilter },
          });
          this.answers = response.data.data || [];
        } catch (error) {
          console.error("Yanıtlar alınırken hata oluştu:", error);
        }
      },
      viewDetails(answerId) {
        this.$router.push({ name: "AnswerManagement", params: { id: answerId } });
      },
    },
    mounted() {
      this.fetchAnswers();
    },
  };
  </script>
  
  <style scoped>
  .answer-list {
    padding: 20px;
  }
  </style>
  