<template>
    <div>
      <h2>Kullanım Kategorileri</h2> 
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Kategori Adı</th>
            <th>Detay</th>
            <th>Oluşturulma Tarihi</th>
            <th>Güncellenme Tarihi</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="category in categories" :key="category.id">
            <td>{{ category.id }}</td>
            <td>
              <div v-if="editing.id === category.id && editing.field === 'name'">
                <input type="text" v-model="category.name" @blur="updateCategory(category)" />
              </div>
              <div v-else @click="setEditing(category.id, 'name')">
                {{ category.name }}
              </div>
            </td>
            <td>
              <div v-if="editing.id === category.id && editing.field === 'detail'">
                <input type="text" v-model="category.detail" @blur="updateCategory(category)" />
              </div>
              <div v-else @click="setEditing(category.id, 'detail')">
                {{ category.detail }}
              </div>
            </td>
            <td>{{ formatDate(category.created_at) }}</td>
            <td>{{ formatDate(category.updated_at) }}</td>
            <td>
              <button class="delete" @click="deleteCategory(category.id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
   
      <h3>Yeni Kategori Ekle</h3>
      <form @submit.prevent="addCategory">
        <div>
          <label>Kategori Adı:</label>
          <input type="text" v-model="newCategory.name" required />
        </div>
        <div>
          <label>Detay:</label>
          <input type="text" v-model="newCategory.detail" />
        </div>
        <button type="submit">Ekle</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default { 
    data() {
      return {
        categories: [],
        newCategory: {
          name: '',
          detail: ''
        },
        editing: {
          id: null,
          field: ''
        }
      };
    },
    methods: {
      // Mevcut kategorileri getirir
      fetchCategories() {
        axios.get('/admin/howtousecategories')
          .then(response => {
            this.categories = response.data;
          })
          .catch(error => {
            console.error('Kategoriler getirilirken hata:', error);
          });
      },
      // Yeni kategori ekler
      addCategory() {
        axios.post('/admin/howtousecategories', this.newCategory)
          .then(response => {
            this.categories.push(response.data);
            this.newCategory = { name: '', detail: '' };
          })
          .catch(error => {
            console.error('Kategori eklenirken hata:', error);
          });
      },
      // Kategori güncelleme işlemi
      updateCategory(category) {
        axios.put(`/admin/howtousecategories/${category.id}`, category)
          .then(() => {
            this.fetchCategories();
            this.editing.id = null;
            this.editing.field = '';
          })
          .catch(error => {
            console.error('Kategori güncellenirken hata:', error);
          });
      },
      // Kategori silme işlemi
      deleteCategory(id) {
        if (confirm('Kategori silinecek, emin misiniz?')) {
          axios.delete(`/admin/howtousecategories/${id}`)
            .then(() => {
              this.categories = this.categories.filter(cat => cat.id !== id);
            })
            .catch(error => {
              console.error('Kategori silinirken hata:', error);
            });
        }
      },
      // Inline düzenleme için alanı ayarlar
      setEditing(id, field) {
        this.editing.id = id;
        this.editing.field = field;
      },
      // Tarih formatlama
      formatDate(dateString) {
        if (!dateString) return '';
        return new Date(dateString).toLocaleString();
      }
    },
    mounted() {
      this.fetchCategories();
    }
  };
  </script>
  
  <style scoped> 
  td div {
    cursor: pointer;
  }
  form div {
    margin-bottom: 10px;
  }
  </style>
  