<template>
  <div class="education-data-management">
    <h3>Eğitim İçeriği Yönetimi</h3>

    <!-- Eğitim-Dil İlişkisi Seçimi -->
    <div class="relation-selection">
      <h4>İlişki Seçimi</h4>
      <select v-model="selectedRelation" @change="onRelationChange">
        <option disabled value="">Eğitim ve Dil Seçin</option>
        <option 
          v-for="relation in educationLanguages" 
          :key="relation.id" 
          :value="relation"
        >
          {{ relation.education && relation.education.name ? relation.education.name : 'Eğitim Yok' }} - 
          {{ relation.language && relation.language.name ? relation.language.name : 'Dil Yok' }}
        </option>
      </select>
    </div>

 <!-- Eğitim-Dil İlişkisi Seçimi ile ilgili veriler -->
    <div class="table-header">
        <h4>Tüm Eğitim Verileri</h4>
        <label>
          <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
        </label>
      </div>

      <table class="education-data-table">
        <thead>
          <tr>
            <th>Seri No</th>
            <th>Başlık</th>
            <th>Alt Başlık</th>
            <th>Masaüstü Görsel</th>
            <th>Mobil Görsel</th>
            <th>Metin Verisi</th>
            <th>Düzenle</th>
            <th v-if="showDeleteButtons">Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="data in educationData" :key="data.data_id">
            <td>{{ data.serial_no }}</td>
            <td>{{ data.title }}</td>
            <td>{{ data.subtitle }}</td>
            <td>
              <img 
                v-if="data.image_link" 
                :src="data.image_link" 
                alt="Görsel" 
                class="image-preview" 
              />
              <span v-else>Yok</span>
            </td>
            <td>
              <img 
                v-if="data.mobile_image_link" 
                :src="data.mobile_image_link" 
                alt="Mobil Görsel" 
                class="image-preview" 
              />
              <span v-else>Yok</span>
            </td>
            <td>{{ data.text_data }}</td>
            <td><button @click="editEducationData(data)">Düzenle</button></td>
            <td v-if="showDeleteButtons">
              <button class="deletebutton" @click="confirmDelete(data.data_id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>



    <!-- Seçili ilişkiye ait eğitim verilerini gösterme ve içerik ekleme formu -->
    <div v-if="selectedRelation">
      <h4>{{ isEdit ? "Eğitim Verisi Düzenle" : "Yeni Eğitim Verisi Ekle" }}</h4>
      <form @submit.prevent="isEdit ? updateEducationData() : createEducationData()">
        <!-- Eğitim ve Dil ID'leri, seçili ilişkiden otomatik aktarılır -->
        <input type="hidden" v-model="selectedEducationData.education_id" />
        <input type="hidden" v-model="selectedEducationData.language_id" />

        <input v-model="selectedEducationData.title" placeholder="Başlık" />
        <input v-model="selectedEducationData.subtitle" placeholder="Alt Başlık" />

        <div>
          <label for="image">Masaüstü Görsel:</label>
          <input class="upload-button" type="file" id="image" @change="handleFileUpload('image', $event)" />
          <img v-if="preview.image" :src="preview.image" alt="Görsel" class="image-preview" />
        </div>

        <div>
          <label for="mobile_image">Mobil Görsel:</label>
          <input class="upload-button" type="file" id="mobile_image" @change="handleFileUpload('mobile_image', $event)" />
          <img v-if="preview.mobile_image" :src="preview.mobile_image" alt="Mobil Görsel" class="image-preview" />
        </div>

        <input v-model="selectedEducationData.serial_no" placeholder="Seri Numarası" required />
        <textarea v-model="selectedEducationData.text_data" placeholder="Metin Verisi"></textarea>
        <button type="submit">{{ isEdit ? "Güncelle" : "Eğitim Verisi Ekle" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </form>

     
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      educationLanguages: [],
      selectedRelation: null,
      educationData: [],
      selectedEducationData: {
        data_id: null,
        education_id: '',
        language_id: '',
        title: '',
        subtitle: '',
        image: null,
        mobile_image: null,
        serial_no: '',
        text_data: '',
      },
      preview: {
        image: null,
        mobile_image: null,
      },
      isEdit: false,
      showDeleteButtons: false,
    };
  },
  watch: {
    // Seçili ilişki değiştiğinde formdaki ilgili ID'leri güncelliyoruz
    selectedRelation(newVal) {
      if (newVal && newVal.education && newVal.language) {
        this.selectedEducationData.education_id = newVal.education.education_id;
        this.selectedEducationData.language_id = newVal.language.language_id;
        this.fetchEducationDataByLang();
        this.clearFormButKeepRelation();
      }
    },
  },
  methods: {
    async fetchEducationLanguages() {
      try {
        const response = await axios.get('/admin/educationlanguages');
        this.educationLanguages = response.data.educationLanguages;
      } catch (error) {
        console.error('Eğitim-Dil ilişkileri alınırken hata:', error);
      }
    },
    async fetchEducationDataByLang() {
      if (!this.selectedRelation) return;
      try {
        const { education_id, language_id } = this.selectedRelation;
        const response = await axios.get('/admin/educationdata/withlang', {
          params: { education_id, language_id },
        });
        this.educationData = response.data.educationData;
      } catch (error) {
        console.error('Eğitim verileri alınırken hata:', error);
      }
    },
    onRelationChange() {
      // Bu metod watcher ile halledildi, ekstra bir işleme gerek yok
    },
    handleFileUpload(type, event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        if (type === 'image') {
          this.preview.image = e.target.result;
          this.selectedEducationData.image = file;
        } else if (type === 'mobile_image') {
          this.preview.mobile_image = e.target.result;
          this.selectedEducationData.mobile_image = file;
        }
      };
      reader.readAsDataURL(file);
    },
    async createEducationData() {
      if (!this.selectedEducationData.serial_no) {
        alert("Seri Numarası boş olamaz!");
        return;
      }
      try {
        const formData = new FormData();
        formData.append('education_id', this.selectedEducationData.education_id);
        formData.append('language_id', this.selectedEducationData.language_id);
        formData.append('title', this.selectedEducationData.title || '');
        formData.append('subtitle', this.selectedEducationData.subtitle || '');
        formData.append('serial_no', this.selectedEducationData.serial_no);
        formData.append('text_data', this.selectedEducationData.text_data || '');
        if (this.selectedEducationData.image) {
          formData.append('image', this.selectedEducationData.image);
        }
        if (this.selectedEducationData.mobile_image) {
          formData.append('mobile_image', this.selectedEducationData.mobile_image);
        }
        await axios.post('/admin/educationdata', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        this.fetchEducationDataByLang();
        this.clearFormButKeepRelation();
      } catch (error) {
        console.error('Eğitim verisi eklenirken hata oluştu:', error);
      }
    },
    async updateEducationData() {
      try {
        const formData = new FormData();
        formData.append('education_id', this.selectedEducationData.education_id);
        formData.append('language_id', this.selectedEducationData.language_id);
        formData.append('title', this.selectedEducationData.title || '');
        formData.append('subtitle', this.selectedEducationData.subtitle || '');
        formData.append('serial_no', this.selectedEducationData.serial_no);
        formData.append('text_data', this.selectedEducationData.text_data || '');
        if (this.selectedEducationData.image) {
          formData.append('image', this.selectedEducationData.image);
        }
        if (this.selectedEducationData.mobile_image) {
          formData.append('mobile_image', this.selectedEducationData.mobile_image);
        }
        await axios.put(`/admin/educationdata/${this.selectedEducationData.data_id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        this.fetchEducationDataByLang();
        this.clearFormButKeepRelation();
      } catch (error) {
        console.error('Eğitim verisi güncellenirken hata oluştu:', error);
      }
    },
    editEducationData(data) {
      this.selectedEducationData = {
        data_id: data.data_id,
        education_id: data.education_id,
        language_id: data.language_id,
        title: data.title,
        subtitle: data.subtitle,
        image: null,
        mobile_image: null,
        serial_no: data.serial_no,
        text_data: data.text_data,
      };
      this.preview = {
        image: data.image_link,
        mobile_image: data.mobile_image_link,
      };
      this.isEdit = true;
    },
    async deleteEducationData(id) {
      try {
        await axios.delete(`/admin/educationdata/${id}`);
        this.fetchEducationDataByLang();
      } catch (error) {
        console.error('Eğitim verisi silinirken hata oluştu:', error);
      }
    },
    confirmDelete(id) {
      if (confirm('Bu eğitim verisini silmek istediğinizden emin misiniz?')) {
        this.deleteEducationData(id);
      }
    },
    clearFormButKeepRelation() {
      // Seçili ilişkiye ait ID'leri koruyarak formu temizliyoruz
      this.selectedEducationData = {
        data_id: null,
        education_id: this.selectedRelation ? this.selectedRelation.education.education_id : '',
        language_id: this.selectedRelation ? this.selectedRelation.language.language_id : '',
        title: '',
        subtitle: '',
        image: null,
        mobile_image: null,
        serial_no: '',
        text_data: '',
      };
      this.preview = { image: null, mobile_image: null };
      this.isEdit = false;
    },
    cancelEdit() {
      this.clearFormButKeepRelation();
    },
  },
  mounted() {
    this.fetchEducationLanguages();
  },
};
</script>

<style scoped>
.relation-selection{
  padding: 1rem;
  background-color: black;
  margin-bottom: 2rem;
}
.education-data-table{margin-bottom: 2rem;}
</style>