<template>
    <div class="container">
      <h3>GPT İsimleri Yönetimi (orijinal modeller)</h3>
      <p style="color: yellow;">Lütfen Dikkat, GPT API leri ile ilgili bilgi sahibi değilseniz modelleri güncellemeyin. Harf ve sembollerde değişiklik hatalara neden olur.</p>
      <p>https://platform.openai.com/docs/models</p>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>GPT Adı</th>
            <th>İşlem</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="gptName in gptNames" :key="gptName.id">
            <td>{{ gptName.id }}</td>
            <td contenteditable @blur="updateGPTName(gptName, 'gpt_name', $event)">
              {{ gptName.gpt_name }}
            </td>
            <td>
              <button class="delete" @click="deleteGPTName(gptName.id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <h3>Yeni GPT Adı Ekle</h3>
      <form @submit.prevent="createGPTName">
        <input type="text" v-model="newGPTName" placeholder="GPT Adı" required />
        <button type="submit">Ekle</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import { ref, onMounted } from "vue";
  
  export default {
    setup() {
      const gptNames = ref([]);
      const newGPTName = ref("");
  
      const fetchGPTNames = async () => {
        try {
          const response = await axios.get("/admin/gpt/gptnames");
          gptNames.value = response.data.gptNames;
        } catch (error) {
          console.error("GPT isimleri alınırken hata:", error);
        }
      };
  
      const updateGPTName = async (gptName, field, event) => {
        const newValue = event.target.innerText;
        try {
          await axios.put(`/admin/gpt/gptnames/${gptName.id}`, { [field]: newValue });
          gptName[field] = newValue;
        } catch (error) {
          console.error("GPT Adı güncellenirken hata:", error);
        }
      };
  
      const deleteGPTName = async (id) => {
        if (!confirm("Bu GPT adını kalıcı olarak silmek istediğinize emin misiniz?")) return;
        try {
          await axios.delete(`/admin/gpt/gptnames/${id}`);
          gptNames.value = gptNames.value.filter((gptName) => gptName.id !== id);
        } catch (error) {
          console.error("GPT Adı silinirken hata:", error);
        }
      };
  
      const createGPTName = async () => {
        try {
          const response = await axios.post("/admin/gpt/gptnames", { gpt_name: newGPTName.value });
          gptNames.value.push(response.data.gptName);
          newGPTName.value = "";
        } catch (error) {
          console.error("GPT Adı eklenirken hata:", error);
        }
      };
  
      onMounted(fetchGPTNames);
  
      return { gptNames, newGPTName, updateGPTName, deleteGPTName, createGPTName };
    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
  }
  
  h3 {
    text-align: center;
  }
    
  .delete:hover {
    background-color: darkred;
  }
  
  form {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  input, button {
    padding: 5px; 
  } 
  </style>
  